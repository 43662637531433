import merch1 from '../../assets/media/merch/merch-1.jpeg';
import merch2 from '../../assets/media/merch/merch-2.jpeg';
import merch3 from '../../assets/media/merch/merch-3.jpeg';
import merch4 from '../../assets/media/merch/merch-4.jpeg';
import merch5 from '../../assets/media/merch/merch-5.jpg';
import merch6 from '../../assets/media/merch/merch-6.jpg';
import merch7 from '../../assets/media/merch/merch-7.jpg';
import merch8 from '../../assets/media/merch/merch-8.jpg';
import merch9 from '../../assets/media/merch/merch-9.jpg';
import merch10 from '../../assets/media/merch/merch-10.jpg';
import merch11 from '../../assets/media/merch/merch-11.jpg';
import merchIcon1 from '../../assets/media/merch/merch-icon-1.png';
import merchIcon2 from '../../assets/media/merch/merch-icon-2.png';
import merchIcon3 from '../../assets/media/merch/merch-icon-3.png';
import merchIcon4 from '../../assets/media/merch/merch-icon-4.png';
import merchIcon5 from '../../assets/media/merch/merch-icon-5.png';
import merchIcon6 from '../../assets/media/merch/merch-icon-6.png';
import merchIcon7 from '../../assets/media/merch/merch-icon-7.png';
import merchIcon8 from '../../assets/media/merch/merch-icon-8.png';
import merchIcon9 from '../../assets/media/merch/merch-icon-9.png';
import merchIcon10 from '../../assets/media/merch/merch-icon-10.png';
import merchIcon11 from '../../assets/media/merch/merch-icon-11.png';
import { merchFilter } from '../types/enums';

const merch: Imerch[] = [
  // {
  //   id: 1,
  //   image: merch1,
  //   icon: merchIcon1,
  //   name: 'ПАНАМА SANTO STEFANO',
  //   descr: 'Легкая панама Santo Stefano с широкими полями и кантом из короткой бахромы. Благодаря аккуратной классической форме и универсальному цвету она легко сочетается с любыми элементами вашего гардероба. Perfetto!',
  //   price: 300,
  //   tag: merchFilter.MAIN,
  //   // discount: 4000
  // },
  // {
  //   id: 2,
  //   image: merch2,
  //   icon: merchIcon2,
  //   name: 'ХУДИ SANTO STEFANO',
  //   descr: 'Худи Santo Stefano с мягкой ворсистой изнанкой обеспечивает комфорт и тепло в любое время года. Универсальный пастельный оттенок и элегантный дизайн позволяют его легко сочетать с любыми элементами повседневного гардероба. Perfetto!<br /><br />Длина по спинке:  64 см<br />Обхват груди: 144 см<br />Длина рукава: 83 см',
  //   price: 600,
  //   tag: merchFilter.MAIN,
  // },
  // {
  //   id: 3,
  //   image: merch3,
  //   icon: merchIcon3,
  //   name: 'СУМКА SANTO STEFANO',
  //   descr: 'Голографический шоппер Santo Stefano станет незаменимым аксессуаром для города, путешествий или спортивных занятий. В разном освещении сумка раскрывает новые, неповторимые оттенки, добавляя вашему образу динамичности. Элегантные ручки цвета шампань придают ей изысканности и комфорта в использовании. Bellissimo!',
  //   price: 400,
  //   tag: merchFilter.MAIN,
  //   // discount: 4000
  // },
  // {
  //   id: 4,
  //   image: merch4,
  //   icon: merchIcon4,
  //   name: 'БАНДАНА SANTO STEFANO',
  //   descr: 'Бандана Santo Stefano - это современный и стильный аксессуар для повседневных образов. Идеально подойдет как для прогулки по городу, так и для смелого решения на вечерний выход. Bellissimo!<br /><br />Размер: 60x60 см<br />Материал: 85% полиэстер и 15% вискоза',
  //   price: 150,
  //   tag: merchFilter.MAIN,
  //   // discount: 4000
  // },
  // {
  //   id: 5,
  //   image: merch5,
  //   icon: merchIcon5,
  //   name: 'СВИТШОТ SANTO STEFANO',
  //   descr: 'Стильный свитшот Santo Stefano - манифест хорошего настроения. Петельная текстура без начеса дарит свободные ощущения, а принт говорит сам за себя: пить Santo Stefano – значит быть счастливым!<br /><br />Длина по спине: 71 см<br />Обхват груди: 63 см<br />Длина рукава: 60 см',
  //   price: 500,
  //   tag: merchFilter.NEW_MERCH,
  //   // discount: 1000
  // },
  {
    id: 6,
    image: merch6,
    icon: merchIcon6,
    name: 'СТИКЕРПАК BIANCO',
    descr: 'Яркие стикеры, посвященные коллекции Zero. Укрась ими свой ноутбук, телефон или блокнот и поднимай настроение себе и окружающим!',
    price: 150,
    tag: merchFilter.MAIN
  },
  {
    id: 7,
    image: merch7,
    icon: merchIcon7,
    name: `СВИТШОТ I DON'T NEED A MAN...`,
    descr: `"I don't need a man, I need… Santo Stefano."<br />Зачем искать «идеального», если Santo Stefano всегда рядом? Мягкий, уютный и стильный свитшот согреет в любое время года.<br /><br />Длина по спине: 71 см<br />Обхват груди: 63 см<br />Длина рукава: 60 см`,
    price: 800,
    tag: merchFilter.NEW_MERCH
  },
  {
    id: 8,
    image: merch8,
    icon: merchIcon8,
    name: 'СВИТШОТ ONCE SANTO STEFANO',
    descr: `"Once Santo Stefano, always Santo Stefano."<br />Этот свитшот говорит сам за себя: однажды Santo Stefano, всегда Santo Stefano! Мягкий, уютный и стильный свитшот согреет в любое время года.<br /><br />Длина по спине: 71 см<br />Обхват груди: 63 см<br />Длина рукава: 60 см`,
    price: 800,
    tag: merchFilter.NEW_MERCH
  },
  {
    id: 9,
    image: merch9,
    icon: merchIcon9,
    name: 'СВЕЧА SANTO STEFANO CASA - TOSCANA',
    descr: 'Тоскана – это место, где время словно растекается по склонам холмов, замедляется, становясь мягче и теплее. Солнце встаёт медленно, его свет ложится на землю словно янтарная вуаль. Аромат спелых фруктов, диких трав и ветра, принесенного с моря, наполняет воздух. Здесь нет спешки, нет суеты — только бесконечный ритм жизни, который напоминает, что всё должно идти своим чередом.<br /><br />Наслаждайтесь простыми радостями и зажигайте по-итальянски вместе с Santo Stefano!<br /><br />Объем: 250 мл<br />Состав: кокосовый воск, ароматические масла, хлопковый фитиль<br />Время непрерывного горения: 48 часов',
    price: 700,
    tag: merchFilter.NEW_MERCH
  },
  {
    id: 10,
    image: merch10,
    icon: merchIcon10,
    name: 'БОКАЛ SANTO STEFANO',
    descr: 'Пластиковый бокал формы Flute идеально подходит для игристых вин и коктейлей. Лаконичный и элегантный — такой, каким и должен быть бокал Santo Stefano. Наполните его праздником по-итальянски и подарите себе и своим близким немного яркости и позитива!<br />Объем: 170 мл',
    price: 500,
    tag: merchFilter.NEW_MERCH
  },
  {
    id: 11,
    image: merch11,
    icon: merchIcon11,
    name: 'СТИКЕРПАК ROSSO',
    descr: 'Яркие стикеры, посвященные коллекции Zero. Укрась ими свой ноутбук, телефон или блокнот. Поднимай настроение себе и окружающим!',
    price: 150,
    tag: merchFilter.NEW_MERCH
  },
];

export default merch;